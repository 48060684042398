import {mapGetters} from "vuex"

export default {
  namespaced: true,
  state: {
    deviceType: 'desktop',
    responsiveMode: false,
    preview: false,
    zoom: 100,
    size: {
      width: '1200',
      height: '1000',
    },
  },
  mutations: {
    SET_DEVICE_TYPE: (state, deviceType) => {
      state.deviceType = deviceType
    },
    SET_PAGE_ZOOM: (state, newZoom) => {
      state.zoom = newZoom
    },
    SET_HEIGHT_SIZE: (state, height) => {
      state.size.height = height
    },
    SET_WIDTH_SIZE: (state, width) => {
      state.size.width = width
    },
  },
  actions: {
    changeDeviceViewport({ commit }, deviceType) {
      commit('SET_DEVICE_TYPE', deviceType)
      
      if (deviceType === 'desktop') {
        commit('SET_WIDTH_SIZE', 1200) // was unset
        commit('SET_HEIGHT_SIZE', 1000) // was unset
      } else if (deviceType === 'tablet') {
        commit('SET_WIDTH_SIZE', 768)
        commit('SET_HEIGHT_SIZE', 1024)
      } else if (deviceType === 'mobile') {
        commit('SET_WIDTH_SIZE', 400)
        commit('SET_HEIGHT_SIZE', 760)
      }
    },
    zoomIn({ state, commit }) {
      const newZoom = Math.min(state.zoom + 10, 200) // Limit zoom to 200%
      commit('SET_PAGE_ZOOM', newZoom)
    },
    zoomOut({ state, commit }) {
      const newZoom = Math.max(state.zoom - 10, 50) // Limit zoom to 50%
      commit('SET_PAGE_ZOOM', newZoom)
    },
    updateFrameWidth({ commit }, width) {
      if (width >= 1200) {
        commit('SET_DEVICE_TYPE', 'desktop')
      } else if (width >= 768) {
        commit('SET_DEVICE_TYPE', 'tablet')
      } else {
        commit('SET_DEVICE_TYPE', 'mobile')
      }

      commit('SET_WIDTH_SIZE', parseInt(width))
    },
    updateFrameHeight({ commit }, height) {
      commit('SET_HEIGHT_SIZE', height ? parseInt(height) : '') // was unset
    },
    rotateLayout({ state, commit }) {
      if (typeof state.size.height !== 'number') return

      const currentHeight = state.size.height
      const currentWidth = state.size.width
      // swap values for height and with to rotate layout
      commit('SET_HEIGHT_SIZE', currentWidth)
      commit('SET_WIDTH_SIZE', currentHeight)
    },
  },
  getters: {
    pageWidth: state => {
      return state.size.width
    },
    pageHeight: state => {
      return state.size.height
    },
    zoomLevel: state => state.zoom,
    deviceType: state => state.deviceType,
  },
}

<template>

  <span>
    <template v-if="debugMode">
      <Export />
      <hr />
      <SettingPanel />
    </template>

    <div id="pb-container" :class="containerClasses" :style="containerStyles">

      <div v-show="pageWidth" class="handle left" @mousedown="startResize($event, 'left')"></div>

      <div>
        <div v-show="isResizing" class="resize-overlay"></div>
        <iframe-wrapper :iframe-style="iframeStyles">
          <!-- #pb-wrapper is used as <html> -->
          <div>
            <component is="style" v-if="appStyles">
              {{ appStyles }}
            </component>
            <component is="style" v-if="designOptionsStyles">
              {{ designOptionsStyles }}
            </component>
            <component is="style" v-if="themeStyles">
              {{ themeStyles }}
            </component>
            <component is="style" v-if="websiteStyles">
              {{ websiteStyles }}
            </component>
            <div id="pb-wrapper">
              <!-- #pb-wrapper-body is used as <body> -->
              <div id="pb-wrapper-body">
                <!-- id="app" is required because of some frontend styles -->
                <Frame component="div" class="preview-panel pb-wrapper" id="app" :key="renderKey"/>
              </div>
            </div>
          </div>
        </iframe-wrapper>
        <div v-show="pageHeight" class="handle bottom" @mousedown="startResize($event, 'bottom')"></div>
      </div>

      <div v-show="pageWidth" class="handle right" @mousedown="startResize($event, 'right')"></div>
    </div>

    <RowTools/>
  </span>




</template>

<script>
import SettingPanel from './components/SettingPanel.vue'
import Export from './components/Export.vue'
import RowTools from './RowTools.vue'
import { Frame } from '../craft'
import { mapActions, mapGetters } from "vuex"
import IframeWrapper from "@/modules/pagebuilder/components/IframeWrapper.vue"
import StyleSheetsMixin from "@/modules/pagebuilder/mixins/StyleSheetsMixin"

export default {
  inject: ['editor'],
  mixins: [StyleSheetsMixin],
  data() {
    return {
      isResizing: false,
      startX: 0,
      startY: 0,
      startWidth: null,
      startHeight: null,
    }
  },

  components: {
    Frame, SettingPanel, Export, RowTools, IframeWrapper,
  },
  methods: {
    ...mapActions('layout', ['updateFrameWidth', 'updateFrameHeight']),
    /*
    updateSize(width, height) {
      this.updateFrameWidth(width)
      this.updateFrameHeight(height)
    },
    initResizeObserver() {
      const resizeObserver = new ResizeObserver(entries => {
        const width = entries[0].contentRect.width
        const height = entries[0].contentRect.height
        if (width && height) {
          this.updateSize(width, height)
        }
      })

      resizeObserver.observe(document.getElementById('pb-wrapper-body'))
    },
    */

    startResize(event, direction) {
      this.isResizing = true
      this.startX = event.clientX
      this.startY = event.clientY
      this.startWidth = this.pageWidth
      this.startHeight = this.pageHeight

      if (direction === 'left') {
        document.addEventListener('mousemove', this.resizeLeft)
      } else if (direction === 'right') {
        document.addEventListener('mousemove', this.resizeRight)
      } else if (direction === 'bottom') {
        document.addEventListener('mousemove', this.resizeBottom)
      }
      document.addEventListener('mouseup', this.stopResize)
    },

    // we need separate method so we can remove the event listener
    resizeLeft(event) {
      if (this.isResizing) {
        const deltaX = event.clientX - this.startX
        this.updateFrameWidth(Math.max(50, this.startWidth - deltaX))
      }
    },
    resizeRight(event) {
      if (this.isResizing) {
        const deltaX = event.clientX - this.startX
        this.updateFrameWidth(Math.max(50, this.startWidth + deltaX))
      }
    },
    resizeBottom(event) {
      if (this.isResizing) {
        const deltaY = event.clientY - this.startY
        this.updateFrameHeight(Math.max(50, this.startHeight + deltaY))
      }
    },

    stopResize() {
      this.isResizing = false
      document.removeEventListener('mousemove', this.resizeLeft)
      document.removeEventListener('mousemove', this.resizeRight)
      document.removeEventListener('mousemove', this.resizeBottom)
      document.removeEventListener('mouseup', this.stopResize)
    },
  },
  computed: {
    ...mapGetters('layout', ['deviceType', 'pageHeight', 'pageWidth', 'zoomLevel']),
    renderKey(){
      return 'craft-'+this.editor.renderKey
    },
    debugMode(){
      return this.$route.query.debug ? true : false
    },
    containerClasses() {
      const classes = []
      if (this.isResizing) {
        classes.push('resizing')
      }

      if (this.$store.state.layout.responsiveMode) {
        classes.push('responsive-mode')
      }

      return classes
    },
    containerStyles() {
      const styles = {
        //zoom: this.zoomLevel + '%',
        scale: this.zoomLevel / 100,
        // transform: `scale(${this.zoomLevel/100})`, // add smooth transition but makes problems with drag and drop indicator todo test it at the end or after vue migrate
      }

      return styles
    },
    iframeStyles() {
      const styles = {}

      if (this.$store.state.layout.responsiveMode) {
        if (this.pageWidth) {
          styles.width = this.pageWidth + 'px'
        }
        if (this.pageHeight) {
          styles.height = this.pageHeight + 'px'
        }
      }

      return styles
    },
    wrapperStyles() {
      const styles = {}

      if (this.$store.state.layout.responsiveMode) {
        if (this.pageHeight) {
          styles.height = this.pageHeight + 'px'
        }
      }

      return styles
    },
  },
  /*
  watch: {
    '$store.state.builder.showLoader': function (newVal) {
      if (!newVal) {
        this.initResizeObserver()
      }
    },
  },
   */
}
</script>

<style lang="scss">
#pb-frame-wrapper {
  margin-top: 15px;
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.5); // same as .builder-header

  #pb-frame {
    outline: 1px solid #dadada;
    width: 100%;
    resize: none;
    overflow: visible;
    height: 100%;
  }
}

#pb-container.responsive-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: scale 300ms;

  &.resizing {
    .handle.left:before, .handle.right:before {
      height: 100px; /* same as #974617714 */
    }
  }

  .resize-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 999;
  }

  #pb-frame {
    resize: none;
    overflow: auto;
    transition: transform 0.3s ease-in-out; // animation on scale
    background: #fff;
  }

  .handle {
    position: relative;

    &.left, &.right {
      cursor: ew-resize;
      align-self: stretch;
      width: 25px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        background-color: rgba(0, 0, 0, 0.2);
        width: 4px;
        height: 70px;
        border-radius: 5px;
        transition: all .2s ease-in-out;
      }

      &:hover:before {
        height: 100px; /* same as #974617714 */
      }
    }

    &.left:before {
      right: 10px;
    }
    &.right:before {
      left: 10px;
    }
    &.bottom:before {
      top: 10px;
    }

    &.bottom {
      height: 25px;
      align-self: stretch;
      cursor: ns-resize;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        background-color: rgba(0, 0, 0, 0.2);

        height: 4px;
        width: 50px;
        border-radius: 5px;
        transition: all .2s ease-in-out;
      }

      &:hover:before {
        width: 100px; /* same as #974617714 */
      }
    }
  }
}
</style>

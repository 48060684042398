// TODO work with craft settings

import Cache from '@/modules/erp_framework/services/request/RequestCaching'
import { mapActions } from "vuex"
import axios from "axios"
import { getWebsiteSettings } from "@/utilities/helpers/websiteSettings"

export default {
  data() {
    return {
      data: null,
      categories: null,
      resData: '',
    }
  },
  methods: {
    ...mapActions('builder', ['fetchApps']),

    /* NOT USED ANYMORE, NOT CONVERTED TO PAGEBUILDER V3
    async loadData(withCategories = false, reload = false, onlyCategories = false) {
        if (!Cache.cache[this.$attrs['data-node-id']] || (Cache.cache[this.$attrs['data-node-id']] && reload)) {
            let query = this.erp.ext.query()
            let id = window.__w || this.$route.params.website
            if (this.entity !== 'inventory')
                query.where('website_id', '=', id, true)

            if (this.settingsParsed.preselected)
                query.where('id', 'in', this.settingsParsed.preselected, true)

            query.set('direction', this.settingsParsed.sortOrder || 'desc')
                .set('column', 'created_at')
                .set('perpage', this.settingsParsed.perpage || null)
                .set('limit', this.settingsParsed.items || null)
                .set('page', this.settingsParsed.page || 1)

            if (this.settings.scopeByCategory) {
                query.where('categories', '=', this.settings.scopeByCategory.id, true)
            }
            if (this.settings.filterBySku) {
                query.where('sku', '=', this.settings.filterBySku, true)
            }
            if (this.settings.filterByKeyword) {
                query.where('name', 'like', `%${this.settings.filterByKeyword}%`, true)
            }

            if (!withCategories) {
                this.data = await this.erp.ext.request.get('/modules' + `/${this.entity}`, query.toString())
                return
            }

            let categoryEntity = this.entity === 'inventory' ? 'inventories' : this.entity

            const [data, categories] = await Promise.all([
                this.erp.ext.request.get('/modules' + `/${this.entity}`, query.toString()),
                this.erp.ext.request.get(
                    '/modules' + '/categories',
                    this.erp.ext
                        .query()
                        .where('entity', '=', categoryEntity)
                        .where('type', '=', 'categories', true)
                        .toString()
                )
            ])

            for (let i in data.data) {
                if (data.data && data.data[i].hasOwnProperty('price'))
                    data.objectData[i].price = data.data[i].price
            }

            this.categories = categories.objectData
            Cache.store(this.$attrs['data-node-id'], data)
        }

        if(onlyCategories) {
            let categoryEntity = this.entity === 'inventory' ? 'inventories' : this.entity

            const categories = await Promise.resolve(
                this.erp.ext.request.get(
                    '/modules' + '/categories',
                    this.erp.ext
                        .query()
                        .where('entity', '=', categoryEntity)
                        .where('type', '=', 'categories', true)
                        .toString()
                )
            )

            this.categories = categories.objectData
        }

        // this.data = data
        this.data = Cache.cache[this.$attrs['data-node-id']]
    },
    */

    async getRenderUrl() {
      // replace render Url if the block is any of those
      if (this.blockName === 'blog') {
        let websiteSettings = await getWebsiteSettings('blogs-settings-cmswebsite')
        if (typeof websiteSettings['blogs.blogs_url'] !== 'undefined' && websiteSettings['blogs.blogs_url'].length) {
          this.renderUrl = websiteSettings['blogs.blogs_url']
        } else {
          this.renderUrl = 'blogs'
        }
      } else if (this.blockName === 'portfolio') {
        let websiteSettings = await getWebsiteSettings('portfolios-settings-cmswebsite')
        if (typeof websiteSettings['portfolios.portfolios_url'] !== 'undefined' && websiteSettings['portfolios.portfolios_url'].length) {
          this.renderUrl = websiteSettings['portfolios.portfolios_url']
        } else {
          this.renderUrl = 'portfolios'
        }
      } else if (this.blockName === 'testimonial') {
        let websiteSettings = await getWebsiteSettings('testimonials-settings-cmswebsite')
        if (typeof websiteSettings['testimonials.testimonials_url'] !== 'undefined' && websiteSettings['testimonials.testimonials_url'].length) {
          this.renderUrl = websiteSettings['testimonials.testimonials_url']
        } else {
          this.renderUrl = 'testimonials'
        }
      } else if (this.blockName === 'store-locator') {
        let websiteSettings = await getWebsiteSettings('store-locator-settings-cmswebsite')
        if (typeof websiteSettings['store_locator.store_locator_url'] !== 'undefined' && websiteSettings['store_locator.store_locator_url'].length) {
          this.renderUrl = websiteSettings['store_locator.store_locator_url']
        } else {
          this.renderUrl = 'stores'
        }
      }

      return this.renderUrl
    },

    async loadContent() {
      // todo show loader
      let renderUrl = await this.getRenderUrl()

      let canRender = false
      // some components can be rendered without any setings
      if (this.blockName === 'blog' || this.blockName === 'portfolio') {
        canRender = true
      } else {
        // else check if there are settings
        canRender = !!this.$attrs['settings']
      }
      if (renderUrl && canRender) {
        this.resData = await this.fetchApps({appName: renderUrl, params: this.$attrs['settings']})
      }
    },
  },

  async mounted() {
    await this.loadContent()
  },


  watch: {
    // on change settings rerender content
    '$attrs.settings': {
      deep: true,
      handler: function (newValue, oldValue) {
        // hot fix check if some options are changed first, somehow this watcher is called even on change selected node
        if (newValue !== oldValue) {
          this.resData = null // shows loader
          this.loadContent()
        }
      },
    },
    /* not used anymore
    query: {
        deep: true,
        handler: function () {
            this.loadData()
        }
    },
    */
  }
}

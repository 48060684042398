<template>
  <div class="property-edit">
    <div class="list-group-item bg-primary border-0">
      <div class="row align-items-center">
        <h6 class="text-white">
          {{ translate('Edit entity', 'entities') }} - {{ entityLabel(model.label) }}
        </h6>
      </div>
    </div>
    <div class="card-body bg-white">
      <div class="row">
        <div class="col-12">
          <tabs>
            <!-- GENERAL INFO -->
            <tab name="general_info" :selected="true">
              <div class="row">
                <div class="form-group required col-12 col-md-6">
                  <label class="control-label">
                    {{ translate('Label', 'entities') }}
                  </label>
                  <ee-form-input v-model="model.label" :field-props="{translatable: 1}" placeholder="Input Label" />
                  <!-- OLD <input type="text" class="form-control" placeholder="Input Label" v-model="model.label"> -->
                </div>
                <div v-if="isAdmin || !model.default" class="form-group required col-12 col-md-6">
                  <label class="control-label">
                    {{ translate('Property name', 'entities') }}
                  </label>

                  <input v-model="model.name" type="text" class="form-control" placeholder="Add Property">
                </div>
                <div class="form-group required col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Width', 'entities') }}
                  </label>
                  <v-select v-model="model.width" :options="widthTypes" :reduce="width => width.key" label="value" class="select2-form" />
                </div>
                <div class="form-group col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Label tooltip', 'entities') }}
                  </label>
                  <input v-model="model.label_tooltip" type="text" class="form-control" placeholder="Add label tooltip">
                </div>
                <div class="form-group col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Description', 'entities') }}
                  </label>
                  <input v-model="model.description" type="text" class="form-control" placeholder="Add description">
                </div>
                <div class="form-group col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Description tooltip', 'entities') }}
                  </label>
                  <input v-model="model.description_tooltip" type="text" class="form-control" placeholder="Add description tooltip">
                </div>
                <div class="form-group required col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Type', 'entities') }}
                  </label>
                  <v-select v-model="model.type" :options="inputTypesOptions" :reduce="value => value.key" :clearable="false" class="select2-form" />
                </div>
              </div>
            </tab>
            <!-- GENERAL INFO END -->

            <!-- PROPERTIES -->
            <tab name="properties">
              <div v-if="isAdmin || !model.default" class="row">
                <div v-if="isAdmin" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Default', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="default_props"
                      v-model="model.default"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="default_props" />
                  </div>
                </div>
                <div class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Visible in table', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="visible_in_table"
                      v-model="model.visible_in_table"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="visible_in_table" />
                  </div>
                </div>
                <div class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Visible in table by default', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="visible_in_table_default"
                      v-model="model.visible_in_table_default"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="visible_in_table_default" />
                  </div>
                </div>
                <div v-if="isAdmin && typeof inputTypesFlat[model.type].filterable !== 'undefined' && inputTypesFlat[model.type].filterable" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Filterable', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="filterable"
                      v-model="model.filterable"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="filterable" />
                  </div>
                </div>
                <div v-if="isAdmin && typeof inputTypesFlat[model.type].translatable !== 'undefined' && inputTypesFlat[model.type].translatable" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Translatable', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="translatable"
                      v-model="model.translatable"
                      type="checkbox"
                      class="custom-control-input"
                      value="false"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="translatable" />
                  </div>
                </div>
                <div v-if="isAdmin && typesWithCreateOption.includes(value.type)" class="form-group col-6 text-center">
                  <label class="form-control-label">
                    {{ translate('Allow to create new option', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="allow_to_create_new"
                      v-model="model.create_new_data_option"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="allow_to_create_new" />
                  </div>
                </div>
                <div v-if="isAdmin" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Searchable', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="searchable"
                      v-model="model.searchable"
                      type="checkbox"
                      class="custom-control-input"
                      value="false"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="searchable" />
                  </div>
                </div>
                <div v-if="isAdmin" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Search weight', 'entities') }}
                  </label>
                  <input
                    id="search_weight"
                    v-model="model.search_weight"
                    type="number"
                    class="form-control"
                  >
                </div>
                <div v-if="isAdmin && entityModel === 'Inventory'" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Required attribute', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="requires_attribute"
                      v-model="model.requires_attribute"
                      type="checkbox"
                      class="custom-control-input"
                      value="false"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="requires_attribute" />
                  </div>
                </div>
                <div v-if="isAdmin && ['text', 'textarea', 'simple_textarea', 'html_editor'].includes(model.type)" class="form-group col-6 text-center">
                  <label class="control-label">
                    {{ translate('Shortcodeable', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="shortcodes_options"
                      v-model="model.shortcodeable"
                      type="checkbox"
                      class="custom-control-input"
                      value="false"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="shortcodes_options" />
                  </div>
                </div>
                <div v-if="multiValuePropertyTypes.includes(model.type)" class="form-group required col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Select data type', 'entities') }}
                  </label>
                  <v-select v-model="model.data_type" :options="dataTypeOptions" :reduce="option => option.key" class="select2-form" @input="model.data_module = null" />
                </div>

                <!-- Data -->
                <div v-if="model.data_type" class="form-group required col-12 col-md-12">
                  <label class="control-label required">
                    {{ translate(dataTypes[model.data_type].optionsLabel, 'entities') }}
                  </label>

                  <v-select v-model="dataModuleModel" :options="dataTypes[model.data_type]['options']" :reduce="option => option.key" class="select2-form"/>
                </div>


                <div v-if="typesWithDataFilters.includes(value.type) && value.data_type === 'moduleNames'" class="form-group col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Data filters', 'entities') }}
                  </label>
                  <v-select v-model="model.data_filters" :options="allPropertiesKeys" multiple class="select2-form"/>
                </div>

                <div class="form-group col-12 col-md-12">
                  <label class="control-label">
                    {{ translate('Select environments', 'entities') }}
                  </label>
                  <v-select v-model="model.environments" :options="['blockz', 'skitle', 'sitecart']" multiple class="select2-form"/>
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between py-2">
                    <div class="custom-control custom-switch">
                      <input
                        id="webshop_filterable"
                        v-model="model.webshop_filterable"
                        type="checkbox"
                        name="webshop_filterable"
                        class="custom-control-input"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label for="webshop_filterable" class="custom-control-label">
                        {{ translate('Filter in webshop', 'attributes') }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ translate('Webshop filter type', 'attributes') }}</label>
                  <v-select v-model="model.webshop_filter_type" class="select2-form" :options="['range']" />
                </div>

                <div class="form-group col-md-6">
                  <label>{{ translate('Attribute location', 'attributes') }}</label>
                  <v-select
                    v-model="model.attribute_location"
                    class="select2-form"
                    :options="positions"
                    :reduce="value => value.key"
                    label="value"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>{{ translate('Attribute location order', 'attributes') }}</label>
                  <input v-model="model.attribute_location_order" class="form-control" type="number">
                </div>

                <div class="form-group col-md-6">
                  <label>{{ translate('Multiply for Module', 'entities') }}</label>
                  <v-select
                    v-model="model.multiply"
                    class="select2-form"
                    :options="multiplyModules"
                    :reduce="value => value.key"
                    label="value"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>{{ translate('Multiply Group Name', 'entities') }}</label>
                  <input v-model="model.multiply_group" type="text" class="form-control">
                </div>

                <!-- DEPRECATED, use default_value shortcodes instead
                <div v-if="multiValuePropertyTypes.includes(model.type) && model.data_type === 'moduleNames'" class="form-group col-md-6">
                  <label class="control-label">
                    {{ translate('Apply default value', 'entities') }}
                  </label>
                  <div class="custom-control custom-switch">
                    <input
                      id="apply_default_value"
                      v-model="model.apply_default_value"
                      type="checkbox"
                      class="custom-control-input"
                      value="true"
                      :true-value="1"
                      :false-value="0"
                    >
                    <label class="custom-control-label" for="apply_default_value" />
                  </div>
                </div>
                -->

                <div class="form-group col-md-12">
                  <label class="form-control-label">
                    {{ translate('Default value', 'entities') }}
                    <!-- Shortcodes are available on dynamic selects -->
                    <span v-if="(multiValuePropertyTypes.includes(model.type) && model.data_type === 'moduleNames') || pickerTypes.includes(model.type)"
                          v-tooltip="translate('Shortcodes', 'global') + ': {website_id}'" class="cursor-pointer align-middle">
                      <i class="fa-solid fa-circle-question" />
                    </span>
                  </label>
                  <input-field
                    v-model="model.default_value"
                    :field-props="{ shortcodeable: 1 }"
                    :shortcodes="customShortcodes"
                  />
                </div>

                <div v-if="hasInputAppend.includes(model.type)" class="form-group col-md-6">
                  <label>{{ translate('Input append', 'entities') }}</label>
                  <input type="text" class="form-control" v-model="model.input_append">
                </div>

                <div v-if="hasAggregation.includes(model.type)" class="form-group col-md-6">
                  <label>{{ translate('Aggregation', 'entities') }}</label>
                  <v-select
                    v-model="model.aggregation"
                    class="select2-form"
                    :options="aggregationOptions"
                    :reduce="value => value.key"
                    label="value"
                  />
                </div>
              </div>

            </tab>
            <!-- PROPERTIES END -->

            <!-- RESTRICTIONS -->
            <tab name="restrictions">
              <permissions-and-restrictions :key="'restrictions-'+model.name" v-model="model.restrictions" :permissions="permissions" />
            </tab>
            <!-- RESTRICTIONS END -->

            <!-- VALIDATION -->
            <tab name="validation">
              <validation v-model="model.rules" :properties="allProperties" :current-property-name="model.name"/>
            </tab>
            <!-- VALIDATION END -->

            <!-- CONDITIONS -->
            <tab name="conditions">
              <conditions v-model="model.conditions" :properties="allProperties" />
            </tab>
            <!-- CONDITIONS END -->
          </tabs>
        </div>
      </div>
    </div>
    <div class="card-footer bg-white d-flex b-t-pr">
      <button
        v-if="isAdmin || (!isAdmin && !model.default)"
        type="button"
        class="btn btn-sm btn-danger"
        @click="$emit('delete')"
      >
        {{ translate('Delete', 'global') }}
      </button>
      <button type="button" class="btn btn-primary btn-sm ml-auto" @click="$emit('close')">
        {{ translate('Close', 'global') }}
      </button>
    </div>
  </div>
</template>

<script>
import { widthTypes, environment, inputTypes } from "../../utilities/helper"

import PermissionsAndRestrictions from "./PermissionsAndRestrictions"
import Validation from './Validation.vue'
import Conditions from './Conditions.vue'
import { mapState } from 'vuex'
import EeFormInput from "@/modules/erp_entities/components/forms/FormInput"
import { erp } from "@/modules/erp_framework"
import store from "@/store"
import DataModelMixin from "@/modules/erp_entities/mixins/DataModelMixin"
import InputField from "@/modules/erp_entities/components/page/form/fields/InputField.vue"
import { customShortcodes } from "@/utilities/lists"

export default {
  name: "PropertyEdit",
  components: {
    EeFormInput,
    PermissionsAndRestrictions,
    Validation,
    Conditions,
    InputField,
  },
  mixins: [DataModelMixin],
  props: {
    entityModel: {
      required: true,
    },
    allProperties: {
      required: true,
    },
    dataTypes: {
      required: true,
    },
  },
  data(){
    return {
      permissions: [
        { key: 'r', value: 'Read' },
        { key: 'w', value: 'Write' },
        { key: 'd', value: 'Deny' },
      ],
      customShortcodes,
    }
  },

  computed: {
    ...mapState('system', ['modules']),
    // custom model required for backward compatibility
    dataModuleModel: {
      get() {
        // backward compatibility for dropdowns using id instead of slug
        if (this.model.data_module && !isNaN(Number(this.model.data_module))) {
          // if id is found in the option, set the slug as value otherwise remove it
          let foundValues = this.dataTypes[this.model.data_type]['options'].filter(option => option.id === Number(this.model.data_module))
          this.model.data_module = foundValues.length ? foundValues[0].key : null
        }

        return this.model.data_module
      },
      set(v) {
        this.model.data_module = v
      },
    },
    allPropertiesKeys(){
      return this.allProperties.reduce((acc, obj) => {
        if(obj.name !== this.model.name){ // skip current property
          acc.push(obj.name)
        }
        return acc
      }, [])
    },
    widthTypes() {
      return widthTypes
    },
    multiValuePropertyTypes() {
      return ['font_upload', 'select', 'multiselect', 'treeselect', 'table_multiselect', 'group_checkbox', 'radio', 'datatable', 'datatable_editable']
    },
    hasInputAppend() {
      return ['text', 'text_responsive', 'int', 'int_responsive', 'number', 'number_responsive']
    },
    typesWithDataFilters() {
      return ['select', 'multiselect', 'datatable', 'datatable', 'datatable_editable']
    },
    typesWithCreateOption() {
      return ['select', 'multiselect', 'checkbox', 'radio', 'datatable', 'datatable_editable']
    },
    hasAggregation() {
      return ['int', 'number']
    },
    dataTypeOptions() {
      return Object.keys(this.dataTypes).map(dataType => {
        return {
          key: dataType,
          label: this.dataTypes[dataType].label,
        }
      })
    },

    positions() {
      return [
        { key: 'above_title', value: 'Above title' },
        { key: 'tabs', value: 'Tabs under products' },
      ]
    },
    multiplyModules() {
      return [
        { key: 'CustomerGroup', value: 'Customer Group Pricing' },
      ]
    },
    isAdmin() {
      return environment.store.getters['system/isAdmin']
    },
    inputTypes() {
      return inputTypes
    },
    inputTypesFlat() {
      let inputTypesFlat = {}
      Object.keys(inputTypes).forEach(inputKey => {
        if (typeof inputTypes[inputKey]['subInputTypes'] !== 'undefined'){
          Object.keys(inputTypes[inputKey]['subInputTypes']).forEach(subInputKey => {
            inputTypesFlat[subInputKey] = Object.assign({}, inputTypes[inputKey]['subInputTypes'][subInputKey])
            inputTypesFlat[subInputKey].label = inputTypes[inputKey].label + ' - ' + inputTypes[inputKey]['subInputTypes'][subInputKey].label // todo #10294166 remove me and use option groups in inputTypesOptions()
          })
        } else {
          inputTypesFlat[inputKey] = Object.assign({}, inputTypes[inputKey])
        }
      })

      return inputTypesFlat
    },
    inputTypesOptions(){
      let options = []
      // todo #10294166 use option groups
      //for (const [key, value] of Object.entries(this.inputTypes)) {
      for (const [key, value] of Object.entries(this.inputTypesFlat)) {
        options.push({
          key: key,
          label: value.label,
        })
      }

      return options
    },

    aggregationOptions() {
      return [
        { key: 'avg', value: 'Average' },
        { key: 'sum', value: 'Sum'},
      ]
    },
    pickerTypes: () => Object.keys(inputTypes.pickers.subInputTypes),
  },
}
</script>

<style scoped lang="scss">
.property-edit {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.prop-table {
    td, th {
        padding: 0.4rem;
    }
}
</style>
